import { devices } from '../controller/controller';
import { deviceConnection } from '../controller/controller';
import { jsonrpc } from '../controller/controller';
import { service } from '../controller/controller';

// Add a delay utility function
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const connectSerial = async ({
  setPort,
  setIsConnected,
  readFromSerialPort
}) => {
  try {
    console.log("Attempting to connect to serial port...");
    const selectedPort = await navigator.serial.requestPort();
    await selectedPort.open({ baudRate: 115200 });

    // Check if the port is opened successfully
    if (selectedPort.readable && selectedPort.writable) {
      // Send } character immediately after connection
      const writer = selectedPort.writable.getWriter();
      await writer.write(new TextEncoder().encode("}\r"));
      writer.releaseLock();

      setPort(selectedPort);
      console.log("Serial port opened successfully.");
      setIsConnected(true);

      // Start reading from the port
      readFromSerialPort(selectedPort);
    } else {
      console.error("Failed to open the serial port.");
    }
  } catch (error) {
    console.error("Error connecting to serial port:", error);
  }
};

export const disconnectSerial = async ({
  port,
  isStreaming,
  keepReading,
  readerRef,
  writerRef,
  setPort,
  setIsConnected,
  setSerialNumber,
  setDeviceDetails,
  setDataPoints,
  setOutput,
  setIsStreaming,
  requestAndSendCommand
}) => {
  try {
    // If streaming is active, stop it first
    if (isStreaming) {
      await requestAndSendCommand({
        commandName: "streamData",
        target: "stop",
        port,
        writerRef,
        setOutput
      });
      setIsStreaming(false);
    }

    // Clean up the writer
    if (writerRef.current) {
      try {
        if (!writerRef.current.closed && !writerRef.current.closing) {
          await writerRef.current.close();
        }
        writerRef.current.releaseLock();
        writerRef.current = null;
      } catch (e) {
        console.warn("Error cleaning up writer during disconnect:", e);
      }
    }

    // Clean up the reader
    if (readerRef.current) {
      try {
        await readerRef.current.cancel();
        readerRef.current.releaseLock();
        readerRef.current = null;
      } catch (e) {
        console.warn("Error cleaning up reader during disconnect:", e);
      }
    }

    // Close the port
    if (port) {
      try {
        await port.close();
      } catch (e) {
        console.warn("Error closing port:", e);
      }
    }

    // Reset all the state
    keepReading.current = false;
    setPort(null);
    setIsConnected(false);
    setSerialNumber(null);
    setDeviceDetails(null);
    setDataPoints([]);
    setOutput([]);

  } catch (error) {
    console.error("Error during disconnect:", error);
    throw error;
  }
};

export const readFromSerialPort = async ({
  selectedPort,
  keepReading,
  readerRef,
  serialNumber,
  setSerialNumber,
  setIsConnected,
  setDeviceDetails,
  processStreamData = null,
  setOutput,
  setLoggingOutput,
  outputType
}) => {
  console.log("🎯 Setting up reader...");
  const reader = selectedPort.readable.getReader();
  readerRef.current = reader;

  console.log("🔌 Setting initial connection state...");
  setIsConnected(true);
  setSerialNumber('dummy-serial');

  try {
    const textDecoder = new TextDecoder();
    let buffer = "";

    console.log("📡 Starting read loop...");
    while (keepReading.current) {
      const { value, done } = await reader.read();
      
      if (done) {
        console.log("🛑 Reader signaled 'done'");
        break;
      }
      
      if (value) {
        // Decode the new chunk
        const chunk = textDecoder.decode(value, { stream: true });
        
        // Clean the chunk of control characters
        const cleanChunk = chunk.replace(/[\x00-\x1F\x7F-\x9F]/g, '');
        buffer += cleanChunk;

        // Try to extract complete JSON objects
        let startIndex = buffer.indexOf('{');
        while (startIndex !== -1) {
          try {
            // Find matching closing brace
            let bracketCount = 0;
            let endIndex = -1;
            
            for (let i = startIndex; i < buffer.length; i++) {
              if (buffer[i] === '{') bracketCount++;
              if (buffer[i] === '}') bracketCount--;
              if (bracketCount === 0) {
                endIndex = i + 1;
                break;
              }
            }

            if (endIndex === -1) break;

            // Extract and clean the JSON string
            let jsonString = buffer.slice(startIndex, endIndex);
            // Additional cleaning of the JSON string
            jsonString = jsonString
              .replace(/[\x00-\x1F\x7F-\x9F]/g, '') // Remove control characters
              .replace(/\r\n|\n|\r/g, ' ') // Replace line breaks with spaces
              .replace(/\s+/g, ' ') // Normalize whitespace
              .trim();

            const parsedData = JSON.parse(jsonString);

            // Handle different message types
            if (parsedData.method === "Stream" && parsedData.result?.pressure) {
              if (processStreamData) processStreamData(parsedData);
              setOutput(prev => [...prev, { type: 'received', content: parsedData }]);
            } else if (parsedData.method?.toLowerCase() === "logging") {
              setLoggingOutput(prev => [...prev, {
                level: parsedData.result?.level || 'app',
                message: parsedData.result?.text,
                timestamp: parsedData.result?.time || new Date().toISOString()
              }]);
            } else {
              setOutput(prev => [...prev, { type: 'received', content: parsedData }]);
            }

            // Remove processed message from buffer
            buffer = buffer.slice(endIndex).trim();
            startIndex = buffer.indexOf('{');
          } catch (err) {
            console.log("⚠️ Error processing message:", err.message);
            // Move to next potential JSON object
            startIndex = buffer.indexOf('{', startIndex + 1);
          }
        }

        // Safety check: clear buffer if it gets too large
        if (buffer.length > 10000) {
          buffer = buffer.slice(buffer.lastIndexOf('{'));
        }
      }
    }
  } catch (error) {
    console.error("❌ Fatal error reading from serial port:", error);
  } finally {
    console.log("👋 Cleaning up reader...");
    reader.releaseLock();
  }
};

export const sendJsonRpcCommand = async (port, writerRef, command) => {
  if (port && port.writable) {
    try {
      console.log(
        "Preparing to send JSON-RPC command to serial device:",
        command
      );

      const writer = port.writable.getWriter();
      writerRef.current = writer;

      console.log("Writing command to serial port...");
      const encodedCommand = new TextEncoder().encode(
        JSON.stringify(command) + "\r"
      );
      await writer.write(encodedCommand);
      console.log("Command written successfully.");

      await writer.close();
      writer.releaseLock();
      writerRef.current = null;
      console.log("Writer lock released.");
    } catch (error) {
      console.error("Error sending JSON-RPC command:", error);
      throw error;
    }
  } else {
    console.warn("Attempted to send command but port is not writable.");
    throw new Error("Please connect to a serial port first.");
  }
};

export const requestAndSendCommand = async ({ commandName, target, port, writerRef, setOutput, interval, level }) => {
  if (!port?.writable) {
    console.warn("Port is not writable");
    return;
  }

  const MAX_RETRIES = 3;
  let retries = 0;

  while (retries < MAX_RETRIES) {
    try {
      let response;
      
      // Use the jsonrpc API methods from controller.js
      switch (commandName) {
        case 'Logging':
          response = await jsonrpc.Logging(target, level);
          break;
          
        case 'getDeviceDetails':
          response = await jsonrpc.getDeviceDetails();
          break;
          
        case 'readDeviceDetails':
          response = await jsonrpc.readDeviceDetails();
          break;
          
        case 'firmwareUpdate':
          response = await jsonrpc.firmwareUpdate();
          break;
          
        case 'resetDevice':
          response = await jsonrpc.resetDevice(target);
          break;
          
        case 'testDevice':
          response = await jsonrpc.testDevice(target);
          break;
          
        case 'streamData':
          response = await jsonrpc.streamData({ 
            action: target, 
            interval: parseInt(interval) 
          });
          break;
          
        default:
          response = await jsonrpc.send({
            jsonrpc: '2.0',
            method: commandName,
            params: { target, level },
            id: new Date().getTime()
          });
      }

      // Write the response to the serial port
      if (writerRef.current) {
        try {
          writerRef.current.releaseLock();
          writerRef.current = null;
        } catch (e) {
          console.warn("Error releasing existing writer:", e);
        }
      }

      await delay(50);

      console.log("Getting new writer...");
      writerRef.current = port.writable.getWriter();
      
      console.log("Writing command to serial port:", response);
      const encodedCommand = new TextEncoder().encode(JSON.stringify(response) + "\r");
      
      await writerRef.current.write(encodedCommand);
      
      writerRef.current.releaseLock();
      writerRef.current = null;
      
      setOutput(prev => [...prev, { type: 'sent', content: response }]);
      console.log("Command written successfully.");
      
      return response;

    } catch (error) {
      console.error(`Error sending command (attempt ${retries + 1}/${MAX_RETRIES}):`, error);
      
      if (writerRef.current) {
        try {
          writerRef.current.releaseLock();
          writerRef.current = null;
        } catch (e) {
          console.warn("Error cleaning up writer after error:", e);
        }
      }

      if (retries === MAX_RETRIES - 1) {
        throw error;
      }

      const waitTime = Math.min(100 * Math.pow(2, retries), 1000);
      console.log(`Waiting ${waitTime}ms before retry...`);
      await delay(waitTime);
      retries++;
    }
  }
};

export const getDeviceDetails = async ({ port, writerRef, setOutput }) => {
  console.log("Triggering getDeviceDetails command...");
  return requestAndSendCommand({
    commandName: 'getDeviceDetails',
    target: 'DeviceDetails',
    port,
    writerRef,
    setOutput
  });
};

export const handleStreamToggle = async ({
  isStreaming,
  streamInterval,
  port,
  writerRef,
  setOutput,
  setIsStreaming
}) => {
  const newStreamingState = !isStreaming;
  console.log("🔄 Toggling stream to:", newStreamingState);
  
  try {
    await requestAndSendCommand({
      commandName: "streamData",
      target: newStreamingState ? "start" : "stop",
      interval: streamInterval,
      port,
      writerRef,
      setOutput
    });
    setIsStreaming(newStreamingState);
  } catch (error) {
    console.error("Failed to toggle stream:", error);
  }
};
