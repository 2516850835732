import React, { createContext, useContext, useState, useRef, useEffect } from 'react';

const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [port, setPort] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [serialNumber, setSerialNumber] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [dataPoints, setDataPoints] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [streamSettings, setStreamSettings] = useState({});
  const [output, setOutput] = useState([]);

  const keepReading = useRef(true);
  const readerRef = useRef(null);
  const writerRef = useRef(null);

  useEffect(() => {
    //console.log("🎮 Streaming state changed:", isStreaming);
  }, [isStreaming]);

  useEffect(() => {
    //console.log("📊 Data points updated in context:", dataPoints.length);
  }, [dataPoints]);

  const value = {
    port,
    setPort,
    isConnected,
    setIsConnected,
    serialNumber,
    setSerialNumber,
    deviceDetails,
    setDeviceDetails,
    isStreaming,
    setIsStreaming,
    dataPoints,
    setDataPoints,
    chartData,
    setChartData,
    streamSettings,
    setStreamSettings,
    output,
    setOutput,
    keepReading,
    readerRef,
    writerRef,
  };

  return (
    <DeviceContext.Provider value={value}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (context === undefined) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }
  return context;
};