import React, { useState, useEffect } from "react";
import { FaPlug, FaTerminal, FaTrash, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import "./SerialCommunicator.css";
import { useDevice } from '../../context/DeviceContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { 
  connectSerial, 
  disconnectSerial, 
  readFromSerialPort,
  requestAndSendCommand,
  getDeviceDetails,
  readDeviceDetails,
  firmwareUpdate
} from '../../utils/serialUtils';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

// Modify the CHART_OPTIONS to be a function that takes the current data
const createChartOptions = (dataPoints, streamInterval) => {
  const annotations = {};
  let currentPumpRegion = null;
  let currentVentRegion = null;
  
  dataPoints.forEach((point, index) => {
    // Handle Pump Regions
    if (index > 0) {
      const prevPump = dataPoints[index - 1]?.pump;
      if (prevPump !== point.pump) {
        if (point.pump) {
          // Pump turned ON - start new region
          currentPumpRegion = {
            type: 'box',
            xMin: index,
            backgroundColor: 'rgba(255, 99, 132, 0.1)',
            borderColor: 'rgba(255, 99, 132, 0.5)',
            borderWidth: 1,
            startTime: point.timestamp,
            label: {
              content: '⬆️ Pump Active',
              display: true,
              position: 'start',
              backgroundColor: 'rgba(255, 99, 132, 0.8)',
              color: 'black',
              font: {
                size: 11,
                weight: 'bold'
              },
              padding: 4
            }
          };
        } else if (currentPumpRegion) {
          // Pump turned OFF - close region and calculate duration
          currentPumpRegion.xMax = index;
          const durationSecs = ((point.timestamp - currentPumpRegion.startTime) / 1000).toFixed(1);
          currentPumpRegion.label.content = `⬆️ Pump (${durationSecs}s)`;
          annotations[`pump${index}`] = currentPumpRegion;
          currentPumpRegion = null;
        }
      }
    }

    // Handle Vent Regions
    if (index > 0) {
      const prevVent = dataPoints[index - 1]?.valves?.[0];
      if (prevVent !== point.valves?.[0]) {
        if (point.valves?.[0]) {
          // Vent turned ON - start new region
          currentVentRegion = {
            type: 'box',
            xMin: index,
            backgroundColor: 'rgba(54, 162, 235, 0.1)',
            borderColor: 'rgba(54, 162, 235, 0.5)',
            borderWidth: 1,
            startTime: point.timestamp,
            label: {
              content: '⬇️ Vent Active',
              display: true,
              position: 'start',
              yAdjust: 20,
              backgroundColor: 'rgba(54, 162, 235, 0.8)',
              color: 'black',
              font: {
                size: 11,
                weight: 'bold'
              },
              padding: 4
            }
          };
        } else if (currentVentRegion) {
          // Vent turned OFF - close region and calculate duration
          currentVentRegion.xMax = index;
          const durationSecs = ((point.timestamp - currentVentRegion.startTime) / 1000).toFixed(1);
          currentVentRegion.label.content = `⬇️ Vent (${durationSecs}s)`;
          annotations[`vent${index}`] = currentVentRegion;
          currentVentRegion = null;
        }
      }
    }
  });

  // Close any open regions at the end of the dataset
  if (currentPumpRegion && dataPoints.length > 0) {
    const lastPoint = dataPoints[dataPoints.length - 1];
    currentPumpRegion.xMax = dataPoints.length - 1;
    const durationSecs = ((lastPoint.timestamp - currentPumpRegion.startTime) / 1000).toFixed(1);
    currentPumpRegion.label.content = `⬆️ Pump (${durationSecs}s)`;
    annotations[`pump${dataPoints.length}`] = currentPumpRegion;
  }
  if (currentVentRegion && dataPoints.length > 0) {
    const lastPoint = dataPoints[dataPoints.length - 1];
    currentVentRegion.xMax = dataPoints.length - 1;
    const durationSecs = ((lastPoint.timestamp - currentVentRegion.startTime) / 1000).toFixed(1);
    currentVentRegion.label.content = `⬇️ Vent (${durationSecs}s)`;
    annotations[`vent${dataPoints.length}`] = currentVentRegion;
  }

  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      y: {
        min: 0,
        max: (context) => {
          const maxValue = Math.max(...context.chart.data.datasets.flatMap(d => d.data));
          return maxValue > 50 ? maxValue + 5 : 50;
        },
        title: {
          display: true,
          text: 'Pressure'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Time'
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pressure Sensors Real-time Data'
      },
      annotation: {
        common: {
          drawTime: 'beforeDatasetsDraw'
        },
        annotations: annotations
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    }
  };
};

const MAX_DATA_POINTS = 3600;

const SerialDeviceCommunicator = () => {
  const {
    port,
    setPort,
    isConnected,
    setIsConnected,
    serialNumber,
    setSerialNumber,
    deviceDetails,
    setDeviceDetails,
    isStreaming,
    setIsStreaming,
    dataPoints,
    setDataPoints,
    chartData,
    setChartData,
    streamSettings,
    setStreamSettings,
    output,
    setOutput,
    keepReading,
    readerRef,
    writerRef
  } = useDevice();

  const [isFormattedView, setIsFormattedView] = useState(true);
  const [resetTarget, setResetTarget] = useState("");
  const [testTarget, setTestTarget] = useState("");
  const [streamInterval, setStreamInterval] = useState(10);
  const [showFiltered, setShowFiltered] = useState(false);
  const [isLogging, setIsLogging] = useState(false);
  const [logLevel, setLogLevel] = useState('app');
  const [outputType, setOutputType] = useState('stream'); // 'stream' or 'logs'
  const [loggingOutput, setLoggingOutput] = useState([]);
  const [pumpStartTime, setPumpStartTime] = useState(null);
  const [ventStartTime, setVentStartTime] = useState(null);
  const [pumpDuration, setPumpDuration] = useState(0);
  const [ventDuration, setVentDuration] = useState(0);

  // Component functions
  const getComponentNames = () => {
    if (!deviceDetails || !deviceDetails.components) return [];
    
    const components = [];
    const { solenoids = [], pressureSensors = [] } = deviceDetails.components;
    
    solenoids.forEach((_, index) => components.push(`solenoid${index + 1}`));
    pressureSensors.forEach((_, index) => components.push(`pressureSensor${index + 1}`));
    
    if (deviceDetails.components.compressor) components.push('compressor');
    if (deviceDetails.components.airFilter) components.push('airFilter');
    
    return components;
  };

  const handleStreamToggle = async () => {
    const newStreamingState = !isStreaming;
    //console.log("🔄 Toggling stream to:", newStreamingState);
    
    try {
      await requestAndSendCommand({
        commandName: "streamData",
        target: newStreamingState,
        interval: streamInterval,
        port,
        writerRef,
        setOutput
      });
      
      setIsStreaming(newStreamingState);
    } catch (error) {
      console.error("Failed to toggle stream:", error);
      // If we get a writer error, try to reset it
      if (writerRef.current) {
        try {
          writerRef.current.releaseLock();
        } catch (e) {
          console.warn("Error releasing writer lock:", e);
        }
        writerRef.current = null;
      }
    }
  };

  // Serial port connection handlers
  const handleConnect = async () => {
    try {
      await connectSerial({
        setPort,
        setIsConnected,
        readFromSerialPort: (selectedPort) => readFromSerialPort({
          selectedPort,
          keepReading,
          readerRef,
          serialNumber,
          setSerialNumber,
          setIsConnected,
          setDeviceDetails,
          processStreamData,
          setOutput,
          setLoggingOutput
        })
      });

      // Start reading immediately after connection
      if (port) {
        //console.log("🔌 Port connected, initializing...");
        
        // Initialize the writer
        if (!writerRef.current && port.writable) {
          //console.log("📝 Getting writer...");
          writerRef.current = port.writable.getWriter();
        }

        //console.log("📡 Starting read loop...");
        startReading(port);
        
        port.addEventListener('disconnect', () => {
          console.warn('🔌 Port disconnected');
          handleDisconnect();
        });
      }
    } catch (error) {
      console.error("❌ Connection failed:", error);
      handleDisconnect();
    }
  };

  // Add this new function to handle the reading stream
  const startReading = async (port) => {
    if (!port || !port.readable) {
      console.error("❌ Port is not readable");
      return;
    }
  
    //console.log("🎯 Starting to read from serial port");
    keepReading.current = true;
    
    try {
      while (port.readable && keepReading.current) {
        //console.log("📡 Getting new reader...");
        readerRef.current = port.readable.getReader();
        
        try {
          while (true) {
            // Log before read
            //console.log("⏳ Waiting for data...");
            
            const { value, done } = await readerRef.current.read();
            
            // Log raw read result
           // console.log("📥 Read result:", { value, done });
            
            if (done) {
             // console.log("🛑 Reader signaled 'done'");
              break;
            }
            
            // Process the received data
            if (value) {
              // Log the raw buffer
             // console.log("📦 Raw buffer:", value);
              
              const textDecoder = new TextDecoder();
              const text = textDecoder.decode(value);
             // console.log("📝 Decoded text:", text);
              
              // Split by newlines and log each piece
              const messages = text.split('\n').filter(msg => msg.trim());
             // console.log("📨 Split messages:", messages);
              
              for (const message of messages) {
              //  console.log("🔍 Processing message:", message);
                
                try {
                  const parsedData = JSON.parse(message);
                //  console.log("✅ Successfully parsed JSON:", parsedData);
                  
                  // Log specific message types
                  if (parsedData.method === "Stream") {
                  //  console.log("📊 Stream data received:", parsedData);
                    processStreamData(parsedData);
                  } else if (parsedData.method === "Logging" || 
                            (parsedData.method === "logging" && parsedData.result)) {
                   // console.log("📝 Log message received:", parsedData);
                    processSerialOutput(parsedData);
                  } else {
                  //  console.log("⚡ Other message type received:", parsedData);
                    setOutput(prev => [...prev, { type: 'received', content: parsedData }]);
                  }
                } catch (e) {
                  console.warn("⚠️ Failed to parse message:", message);
                  console.error("Parse error:", e);
                  
                  // Still try to display unparseable messages
                  setOutput(prev => [...prev, { 
                    type: 'received', 
                    content: { raw: message, error: 'Failed to parse' } 
                  }]);
                }
              }
            } else {
             // console.log("⚠️ Received empty value");
            }
          }
        } catch (error) {
          console.error("❌ Error in read loop:", error);
        } finally {
         // console.log("🔄 Releasing reader lock");
          readerRef.current.releaseLock();
        }
      }
    } catch (error) {
      console.error("💥 Fatal reading error:", error);
    }
    
    console.log("👋 Read loop ended");
  };

  const handleDisconnect = async () => {
    try {
      // First stop streaming if active
      if (isStreaming) {
        await requestAndSendCommand({
          commandName: "streamData",
          target: false,
          port,
          writerRef,
          setOutput
        });
        setIsStreaming(false);
      }

      // Then disconnect
      await disconnectSerial({
        port,
        isStreaming,
        keepReading,
        readerRef,
        writerRef,
        setPort,
        setIsConnected,
        setSerialNumber,
        setDeviceDetails,
        setDataPoints,
        setOutput,
        setIsStreaming,
        requestAndSendCommand
      });

      // Clear references
      readerRef.current = null;
      writerRef.current = null;
    } catch (error) {
      console.error("Disconnect error:", error);
      // Force reset state even if disconnect fails
      setIsConnected(false);
      setPort(null);
      setSerialNumber(null);
    }
  };

  // Add this effect to monitor connection status
  useEffect(() => {
    if (isConnected && port) {
      const checkConnection = setInterval(async () => {
        if (!port.readable || !port.writable) {
          console.warn("Port no longer readable/writable");
          handleDisconnect();
        }
      }, 1000);

      return () => clearInterval(checkConnection);
    }
  }, [isConnected, port]);

  // Data processing handlers
  const processStreamData = (data) => {
    console.log("🔄 processStreamData called with:", data);
  
    if (data.method === "Stream" && data.result) {
      const timestamp = Date.now();
      const { pressure, valves, mode, timers, pump } = data.result;
  
      // Handle pump duration tracking
      if (pump && !pumpStartTime) {
        setPumpStartTime(new Date());
      } else if (!pump && pumpStartTime) {
        const duration = Math.round((new Date() - pumpStartTime) / 1000);
        setPumpDuration(duration);
        setPumpStartTime(null);
      }

      // Handle vent duration tracking
      if (valves?.[0] && !ventStartTime) {
        setVentStartTime(new Date());
      } else if (!valves?.[0] && ventStartTime) {
        const duration = Math.round((new Date() - ventStartTime) / 1000);
        setVentDuration(duration);
        setVentStartTime(null);
      }

      if (pressure) {
        const currentPressures = pressure.current.map(value => value / 10);
        const filteredPressures = pressure.filtered.map(value => value / 10);
        const targetPressures = pressure.target.map(value => value / 10);
  
        setDataPoints(prevPoints => {
          const newPoints = [...prevPoints, {
            time: new Date().toLocaleTimeString(),
            timestamp: timestamp,
            current: currentPressures,
            filtered: filteredPressures,
            pump: pump,
            valves: valves
          }];
          return newPoints.slice(-MAX_DATA_POINTS);
        });
  
        setStreamSettings(prevSettings => ({
          ...prevSettings,
          mode,
          targets: targetPressures,
          valves,
          timers,
          pump
        }));
      }
    }
  };

  // UI handlers
  const clearOutput = () => {
    setOutput([]);
  };

  const toggleView = () => {
    setIsFormattedView(!isFormattedView);
  };

  // Effects
  // useEffect(() => {
  //   if (isConnected && serialNumber) {
  //     getDeviceDetails({ port, writerRef, setOutput });
  //   }
  // }, [isConnected, serialNumber]);

  useEffect(() => {
    if (dataPoints.length > 0) {
      const channelNames = [
        'Static',
        'A',
        'B',
        'Channel 5',
        'Channel 6',
        'Channel 7',
        'Channel 8'
      ];

      const labels = dataPoints.map(point => point.time);
      const datasets = Array.from({ length: 7 }, (_, index) => ({
        label: channelNames[index],
        data: dataPoints.map(point => 
          point[showFiltered ? 'filtered' : 'current'][index + 1]
        ),
        borderColor: `hsl(${(index * 360) / 7}, 70%, 50%)`,
        borderWidth: 2,
        tension: 0.1,
        fill: false,
        pointRadius: 0
      }));

      setChartData({
        labels,
        datasets
      });
    }
  }, [dataPoints, showFiltered]);



  // Add cleanup on component unmount
  useEffect(() => {
    return () => {
      // Cleanup function
      if (writerRef.current) {
        try {
          writerRef.current.releaseLock();
          writerRef.current = null;
          //console.log("Writer lock released during cleanup");
        } catch (e) {
          console.warn("Error releasing writer lock during cleanup:", e);
        }
      }
      if (readerRef.current) {
        try {
          readerRef.current.releaseLock();
          readerRef.current = null;
          //console.log("Reader lock released during cleanup");
        } catch (e) {
          console.warn("Error releasing reader lock during cleanup:", e);
        }
      }
    };
  }, []);

  // Add this function to handle logging messages separately
  const processSerialOutput = (data) => {
    try {
      // Check if it's a logging message (both new and old format)
      if ((data.method === "Logging" && data.params?.message) || 
          (data.method === "logging" && data.result)) {
        
        let logMessage, logLevel, timestamp;
        
        if (data.method === "Logging") {
          // Handle new format
          logMessage = data.params.message;
          logLevel = data.params.level || 'app';
          timestamp = new Date().toLocaleTimeString();
        } else {
          // Handle format from your example
          logMessage = data.result.text;
          logLevel = data.result.level;
          timestamp = data.result.time;
        }

        setLoggingOutput(prev => [...prev, { 
          type: 'log', 
          level: logLevel,
          message: logMessage,
          timestamp: timestamp
        }]);
      } else {
        // Handle regular stream output
        setOutput(prev => [...prev, { type: 'received', content: data }]);
      }
    } catch (error) {
      console.error("Error processing serial output:", error);
    }
  };

  const downloadLogs = (logs, filename) => {
    const blob = new Blob([logs.join('\n')], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Render
  return (
    <div className="serial-communicator-page">
      <div className="content">
        <div className="content-header">
          <div className="content-title">
            <FaPlug className="content-icon" />
            <h2>Serial Port Communicator</h2>
          </div>
          <div className="connection-controls">
            {!isConnected ? (
              <button onClick={handleConnect} className="connect-btn">
                Connect to Serial Port
              </button>
            ) : (
              <button onClick={handleDisconnect} className="disconnect-btn">
                Disconnect Serial Port
              </button>
            )}
          </div>
        </div>

        {/* Only show device info and commands when connected and verified */}
        {isConnected && serialNumber && (
          <>
            <div className="device-info-section">
              <h3>Device Details</h3>
              {deviceDetails && (
                <div className="device-details-grid">
                  {/* General & Features - Combined into one card */}
                  <div className="detail-card">
                    <div className="card-header">
                      <h4>System Info</h4>
                    </div>
                    <div className="card-content compact">
                      <div className="detail-item">
                        <span className="label">Version</span>
                        <span className="value">{deviceDetails.version}</span>
                      </div>
                      <div className="detail-item">
                        <span className="label">Mode</span>
                        <span className="value">{deviceDetails.features?.mode}</span>
                      </div>
                      <div className="detail-item">
                        <span className="label">Cooling</span>
                        <span className="value">{deviceDetails.features?.cooling_feature ? "Enabled" : "Disabled"}</span>
                      </div>
                    </div>
                  </div>

                  {/* Parameters */}
                  <div className="detail-card">
                    <div className="card-header">
                      <h4>Parameters</h4>
                    </div>
                    <div className="card-content compact">
                      <div className="detail-grid">
                        <div className="detail-item">
                          <span className="label">Alarm</span>
                          <span className="value">{deviceDetails.parameters?.alarm_mute ? "Muted" : "Active"}</span>
                        </div>
                        <div className="detail-item">
                          <span className="label">Alt. Cycle</span>
                          <span className="value">{deviceDetails.parameters?.alternating_cycle}</span>
                        </div>
                        <div className="detail-item">
                          <span className="label">Comfort</span>
                          <span className="value">{deviceDetails.parameters?.comfort_setting}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Components */}
                  <div className="detail-card full-width">
                    <div className="card-header">
                      <h4>Components Counters</h4>
                    </div>
                    <div className="card-content">
                      <div className="components-overview">
                        <div className="main-components">
                          <div className="detail-item">
                            <span className="label">Compressor</span>
                            <span className="value">{deviceDetails.components?.compressor}</span>
                          </div>
                          <div className="detail-item">
                            <span className="label">Air Filter</span>
                            <span className="value">{deviceDetails.components?.airFilter}</span>
                          </div>
                        </div>
                        <div className="component-lists">
                          <div className="solenoids-list">
                            <h5>Solenoids</h5>
                            <div className="value-list">
                              {deviceDetails.components?.solenoids?.map((value, index) => (
                                <div key={`sol-${index}`} className="value-item">
                                  <small>{index + 1}</small>
                                  <span>{value}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="sensors-list">
                            <h5>Pressure Sensors</h5>
                            <div className="value-list">
                              {deviceDetails.components?.pressureSensors?.map((value, index) => (
                                <div key={`sensor-${index}`} className="value-item">
                                  <small>{index + 1}</small>
                                  <span>{value}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="command-section">
              <h3>Commands</h3>
              <div className="commands-grid">
                {/* System Commands Card */}
                <div className="command-card">
                  <div className="card-header">
                    <h4>System Commands</h4>
                  </div>
                  <div className="card-content">
                    <button 
                      onClick={() => getDeviceDetails({ port, writerRef, setOutput })} 
                      className="command-btn primary"
                    >
                      Get Device Details
                    </button>
                    <button 
                      onClick={() => requestAndSendCommand({
                        commandName: "readDeviceDetails",
                        port,
                        writerRef,
                        setOutput
                      })} 
                      className="command-btn primary"
                    >
                      Read Device Details
                    </button>
                    <button 
                      onClick={() => {
                        if (window.confirm('Are you sure you want to start a firmware update? This will restart the device.')) {
                          requestAndSendCommand({
                            commandName: "firmwareUpdate",
                            port,
                            writerRef,
                            setOutput
                          });
                        }
                      }} 
                      className="command-btn warning"
                    >
                      Firmware Update Mode
                    </button>
                  </div>
                </div>

                {/* Stream Control Card */}
                <div className="command-card">
                  <div className="card-header">
                    <h4>Stream Control</h4>
                  </div>
                  <div className="card-content">
                    <div className="stream-controls">
                      <div className="stream-buttons">
                        <button
                          onClick={() => handleStreamToggle()}
                          className={`command-btn ${isStreaming ? 'secondary' : 'primary'}`}
                        >
                          {isStreaming ? 'Stop Stream' : 'Start Stream'}
                        </button>
                      </div>
                      <div className="stream-settings">
                        <label htmlFor="interval-input">Interval (ms)</label>
                        <input
                          id="interval-input"
                          type="number"
                          min="1"
                          max="1000"
                          value={streamInterval}
                          onChange={(e) => setStreamInterval(Number(e.target.value))}
                          disabled={isStreaming}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Device Control Card */}
                <div className="command-card">
                  <div className="card-header">
                    <h4>Device Control</h4>
                  </div>
                  <div className="card-content">
                    <div className="control-group">
                      <select
                        value={resetTarget}
                        onChange={(e) => setResetTarget(e.target.value)}
                        className="target-select"
                      >
                        <option value="">Select Component to Reset</option>
                        {getComponentNames().map((component, index) => (
                          <option key={index} value={component}>
                            {component}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={() => resetTarget && requestAndSendCommand({
                          commandName: "resetDevice",
                          target: resetTarget,
                          port,
                          writerRef,
                          setOutput
                        })}
                        disabled={!resetTarget}
                        className="command-btn secondary"
                      >
                        Reset Component
                      </button>
                    </div>
                    <div className="control-group">
                      <select
                        value={testTarget}
                        onChange={(e) => setTestTarget(e.target.value)}
                        className="target-select"
                      >
                        <option value="">Select Component to Test</option>
                        {getComponentNames().map((component, index) => (
                          <option key={index} value={component}>
                            {component}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={() => testTarget && requestAndSendCommand({
                          commandName: "testDevice",
                          target: testTarget,
                          port,
                          writerRef,
                          setOutput
                        })}
                        disabled={!testTarget}
                        className="command-btn secondary"
                      >
                        Test Component
                      </button>
                    </div>
                  </div>
                </div>

                {/* Logging Control Card */}
                <div className="command-card">
                  <div className="card-header">
                    <h4>Logging Control</h4>
                  </div>
                  <div className="card-content">
                    <div className="logging-controls">
                      <select
                        value={logLevel}
                        onChange={(e) => setLogLevel(e.target.value)}
                        className="log-level-select"
                      >
                      <option value="all">All</option>
                        <option value="app">App</option>
                        <option value="info">Info</option>
                        <option value="debug">Debug</option>
                        <option value="error">Error</option>
                      </select>
                      <button
                        onClick={async () => {
                          try {
                            await requestAndSendCommand({
                              commandName: "Logging",
                              target: (!isLogging).toString(),
                              level: logLevel,
                              port,
                              writerRef,
                              setOutput
                            });
                            setIsLogging(!isLogging);
                          } catch (error) {
                            console.error("Failed to toggle logging:", error);
                          }
                        }}
                        className={`command-btn ${isLogging ? 'secondary' : 'primary'}`}
                      >
                        {isLogging ? 'Stop Logging' : 'Start Logging'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Only show chart section when streaming is active */}
        {isStreaming && (
          <div className="chart-section">
            <div className="chart-controls" style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '10px',
              marginBottom: '10px' 
            }}>
              <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <input
                  type="checkbox"
                  checked={showFiltered}
                  onChange={(e) => setShowFiltered(e.target.checked)}
                />
                Show Filtered Values
              </label>
            </div>
            <div className="settings-bar" style={{ 
              display: 'flex', 
              flexDirection: 'column',
              gap: '10px', 
              padding: '10px', 
              backgroundColor: '#f5f5f5', 
              borderRadius: '4px',
              marginBottom: '10px'
            }}>
              <div className="setting-item">
    <strong>Mode:</strong> {streamSettings.mode}
  </div>
  {streamSettings.timers && (
    <div className="timers-grid" style={{ 
      display: 'grid', 
      gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', 
      gap: '10px' 
    }}>
      <div className="timer-item" style={{ padding: '5px', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
        <div style={{ fontWeight: 'bold' }}>Cycle Timer</div>
        <div>{streamSettings.timers.cycle}</div>
      </div>
      <div className="timer-item" style={{ padding: '5px', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
        <div style={{ fontWeight: 'bold' }}>Check Timer</div>
        <div>{streamSettings.timers.check}</div>
      </div>
    </div>
  )}
              <div className="targets-grid" style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                gap: '10px'
              }}>
                {/* Pump Status Box */}
                <div className="target-item" style={{
                  padding: '5px',
                  backgroundColor: streamSettings.pump ? '#e6ffe6' : 'white',
                  borderRadius: '4px',
                  textAlign: 'center'
                }}>
                  <div style={{ fontWeight: 'bold' }}>Pump</div>
                  <div>Status: {streamSettings.pump ? 'ON' : 'OFF'}</div>
                  {pumpStartTime ? (
                    <div>Duration: {Math.round((new Date() - pumpStartTime) / 1000)}s</div>
                  ) : pumpDuration > 0 ? (
                    <div>Last: {pumpDuration}s</div>
                  ) : null}
                </div>

                {/* Vent Status Box */}
                <div className="target-item" style={{
                  padding: '5px',
                  backgroundColor: streamSettings.valves?.[0] ? '#e6ffe6' : 'white',
                  borderRadius: '4px',
                  textAlign: 'center'
                }}>
                  <div style={{ fontWeight: 'bold' }}>Vent</div>
                  <div>Status: {streamSettings.valves?.[0] ? 'ON' : 'OFF'}</div>
                  {ventStartTime ? (
                    <div>Duration: {Math.round((new Date() - ventStartTime) / 1000)}s</div>
                  ) : ventDuration > 0 ? (
                    <div>Last: {ventDuration}s</div>
                  ) : null}
                </div>

                {streamSettings.targets?.slice(1).map((target, index) => {
                  const channelName = ['Static', 'A', 'B', 'Channel 5', 'Channel 6', 'Channel 7', 'Channel 8'][index];
                  const latestPoint = dataPoints[dataPoints.length-1];
                  return (
                    <div key={index} className="target-item" style={{
                      padding: '5px',
                      backgroundColor: streamSettings.valves?.[index + 1] ? '#e6ffe6' : 'white',
                      borderRadius: '4px',
                      textAlign: 'center'
                    }}>
                      <div style={{ fontWeight: 'bold' }}>{channelName}</div>
                      <div>Target: {target.toFixed(1)}</div>
                      <div>Raw: {(latestPoint?.current[index + 1] || 0).toFixed(1)}</div>
                      <div>Filtered: {(latestPoint?.filtered[index + 1] || 0).toFixed(1)}</div>
                      <div>Valve: {streamSettings.valves?.[index + 1] ? 'ON' : 'OFF'}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="chart-container" style={{ height: '500px' }}>
              <Line 
                data={chartData} 
                options={createChartOptions(dataPoints, streamInterval)}
              />
            </div>
          </div>
        )}

        <div className="output-section">
          <div className="output-header">
            <div className="output-title">
              <FaTerminal className="output-icon" />
              <h3>Output</h3>
            </div>
            <div className="output-controls" style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              {/* Output Type Toggle */}
              <div className="output-type-toggle" style={{ 
                display: 'flex', 
                alignItems: 'center', 
                backgroundColor: '#f0f0f0', 
                borderRadius: '4px', 
                padding: '4px',
                marginRight: '8px'
              }}>
                <button 
                  onClick={() => setOutputType('stream')}
                  style={{
                    padding: '4px 12px',
                    border: 'none',
                    borderRadius: '3px',
                    backgroundColor: outputType === 'stream' ? '#000' : 'transparent',
                    color: outputType === 'stream' ? 'white' : '#333',
                    cursor: 'pointer',
                    transition: 'all 0.2s'
                  }}
                >
                  Stream
                </button>
                <button 
                  onClick={() => setOutputType('logs')}
                  style={{
                    padding: '4px 12px',
                    border: 'none',
                    borderRadius: '3px',
                    backgroundColor: outputType === 'logs' ? '#000' : 'transparent',
                    color: outputType === 'logs' ? 'white' : '#333',
                    cursor: 'pointer',
                    transition: 'all 0.2s'
                  }}
                >
                  Logs
                </button>
              </div>

              {/* Existing Format Toggle */}
              <button onClick={toggleView} className="toggle-view-btn">
                {isFormattedView ? <FaToggleOn /> : <FaToggleOff />}
                {isFormattedView ? ' Formatted' : ' Single-line'}
              </button>

              {/* Clear Button */}
              <button 
                onClick={() => outputType === 'stream' ? clearOutput() : setLoggingOutput([])} 
                className="clear-output-btn"
              >
                <FaTrash /> Clear {outputType === 'stream' ? 'Stream' : 'Logs'}
              </button>

              {/* Download Button */}
              <button 
                onClick={() => {
                  const logs = outputType === 'stream' 
                    ? output.map(item => JSON.stringify(item.content))
                    : loggingOutput.map(item => `${item.timestamp} [${item.level.toUpperCase()}] ${item.message}`);
                  downloadLogs(logs, `${outputType}-logs.txt`);
                }} 
                className="download-logs-btn"
              >
                Download {outputType === 'stream' ? 'Stream' : 'Logs'}
              </button>
            </div>
          </div>
          <div className="output-content">
            {outputType === 'stream' ? (
              // Stream output (keep your existing output rendering)
              output.map((item, index) => (
                <pre key={index} className={`output-item ${item.type}`}>
                  {item.type === 'sent' ? 'Sent command:' : 'Received:'}
                  {'\n'}
                  {(() => {
                    try {
                      const parsedContent = typeof item.content === 'string' ? JSON.parse(item.content) : item.content;
                      return isFormattedView 
                        ? JSON.stringify(parsedContent, null, 2)
                        : JSON.stringify(parsedContent);
                    } catch (e) {
                      return item.content;
                    }
                  })()}
                </pre>
              ))
            ) : (
              // Logging output
              loggingOutput.map((item, index) => (
                <div 
                  key={index} 
                  className={`log-item ${item.level}`}
                  style={{
                    padding: '8px',
                    borderBottom: '1px solid #eee',
                    fontFamily: 'monospace'
                  }}
                >
                  <span className="timestamp" style={{ color: '#666', marginRight: '10px' }}>
                    {item.timestamp}
                  </span>
                  <span 
                    className="level" 
                    style={{ 
                      marginRight: '10px',
                      padding: '2px 6px',
                      borderRadius: '3px',
                      backgroundColor: item.level === 'debug' ? '#e2e3e5' : 
                                     item.level === 'info' ? '#cce5ff' : 
                                     '#d4edda',
                      color: item.level === 'debug' ? '#383d41' :
                             item.level === 'info' ? '#004085' :
                             '#155724'
                    }}
                  >
                    {item.level.toUpperCase()}
                  </span>
                  <span className="message">
                    {item.message}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerialDeviceCommunicator;
